import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "./externalRangePicker.scss";
import {cs} from "date-fns/locale";

const ExternalRangePickerApp = () => {
    const [startDate, setStartDate] = React.useState(null)
    const [endDate, setEndDate] = React.useState(null);
    const [error, setError] = React.useState(null);

    const datePickerConfig = {
        dateFormat: "dd.MM.yyyy",
        locale: cs,
    }

    const handleSubmit = () => {
        if (!startDate || !endDate) {
            setError("Pro vyhledání je nutné vyplnit termín vyzvednutí a vrácení.");
            return;
        }
        // Redirect to search page with selected dates
        let baseUrl = 'https://rezervace.ckao.cz/rezervace';
        let url = new URL(baseUrl);
        
        url.searchParams.append('list_date_from', moment(startDate).format('DD.MM.YYYY'));
        url.searchParams.append('list_date_to', moment(endDate).format('DD.MM.YYYY'));

        window.open(url, '_blank');

    }

    return <div className={"propujcovny-external-range-picker-wrapper"}>
        <div className={"form"}>
        <span className={"label"}>
            Vyberte termín
        </span>
            <div className={"datePicker"}>
                <DatePicker selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            {...datePickerConfig}
                            placeholderText="Datum vyzvednutí"
                />
            </div>
            <div className={"datePicker"}>
                <DatePicker selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            {...datePickerConfig}
                            minDate={startDate}
                            placeholderText="Datum vrácení"
                />
            </div>
            <div className={"submit"}>
                <button className={"button"} onClick={handleSubmit}>Vyhledat</button>
            </div>
        </div>
        {error && <div className={"error"}>{error}</div>}
    </div>

}

export default ExternalRangePickerApp;