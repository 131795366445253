import '../../shared';
import '../../modules/front';
import React from 'react';
import ReactDOM from 'react-dom/client';

// Replace id="externalRangePickerApp" with React.createElement(ExternalRangePicker, {

import ExternalRangePickerApp from "@/modules/externalRangePicker/ExternalRangePickerApp";

document.addEventListener('DOMContentLoaded', () => {
    const root = ReactDOM.createRoot(document.getElementById('propujcovny-range-picker-app'));
    root.render(<ExternalRangePickerApp />);
});

// External range picker installation:
// <link rel="stylesheet" href="{$basePath}/dist/externalRangePicker.bundle.css">
// <div id="propujcovny-range-picker-app"></div>
// <script src="{$basePath}/dist/externalRangePicker.bundle.js"></script>