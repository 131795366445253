import { Popover, Modal } from 'bootstrap'

// UI for front
console.log('Front');

const el = document.getElementsByClassName('show-md');
if (window.innerWidth > 768) {
	  for (let i = 0; i < el.length; i++) {
		el[i].classList.add('show');
	  }
}

var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
	return new Popover(popoverTriggerEl)
})


// Modal
const modalEl = document.getElementById('alertModal');
let modal = null;
if (modalEl) {
	modal = new Modal(modalEl)
	console.log(modalEl.getAttribute('data-show'));
	if (modalEl && modalEl.getAttribute('data-show') === 'true') {

		modal.show()
	}
}

const dismissModalAndSetCookie = () => {
	if (modal) {
		modal.hide();
		document.cookie = "modalDismissed=true;max-age=300";
	}
}
window.dismissModalAndSetCookie = dismissModalAndSetCookie;